import './frontend.scss';

function formatEarnings(num, digits) {
    var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
        decimal;

    for(var i=units.length-1; i>=0; i--) {
        decimal = Math.pow(1000, i+1);

        if(num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return num;
}

function getScoreboard(){
    var apiURL = 'https://site.api.espn.com/apis/v2/scoreboard/header?sport=golf&league=pga';
    // var apiURL = 'https://site.api.espn.com/apis/site/v2/sports/golf/pga/scoreboard';
    jQuery.getJSON(apiURL, function(data) {
        updateDOM(data);
    });
};

function updateDOM(data){
    // The parent rankings element
    const tableEl = document.querySelector( ".HeaderScoreboard__SportSection" );
    const dataJSON = data;

    // Print a little note in the console
    console.log( "Data from the front end", dataJSON);
    
  
  //   // Current Event
    let event = dataJSON.sports[0].leagues[0].events[0];
    const eventLabel = document.querySelector( ".HeaderScoreboard__Label" ); 
  
    eventLabel.innerHTML = '<h2 class="clr-brand-ESPN">' + event.name + '</h2>';
    eventLabel.innerHTML += '<h3>' + event.fullStatus.type.detail + '</h3>';
  
    // Competitors
    let competitors = event.competitors;
  
    competitors.forEach( ( competitor, index ) => {
      const competitorDiv = document.createElement( "div" );
      competitorDiv.classList.add("ScoreCellAthlete","bg-clr-white","relative","overflow-hidden","golf","ScoreCellAthlete--sm","flex-expand","br","brdr-clr-gray-06","ScoreCellAthlete--in");
  
      competitorDiv.innerHTML = `
  
          <a class="ScoreCellAthlete__Link flex relative w-100 flex-column" href="${ event.links[0].href }">
              <div class="ScoreCellAthlete__Team flex relative w-100 overflow-hidden items-center">
                  <div class="ScoreCell__Rank ttn ScoreCell__Rank--athlete n10 ScoreCellAthlete__Rank flex-none"> </div>
                      <img alt="" class="Image Logo ScoreCellAthlete__Logo flex-none mr3 Logo__xs" data-mptype="image" src="${ competitor.logo }" />
                      <div class="ScoreCell__Truncate clr-gray-01 flex flex-column h9">
                          <div class="ScoreCell__TeamName ScoreCell__TeamName--abbev truncate db">${ competitor.shortName }</div>
                      </div>
                  </div>
              <div class="ScoreCellAthlete__Details flex justify-end flex-row items-center">
                  <div class="ScoreCell__Score h7 clr-gray-01 fw-heavy ScoreCellAthlete__Score">${ competitor.score }</div>
                  <div class="ScoreCellAthlete__SecondaryDetails flex-none relative nowrap n10 clr-gray-04">$${(event.fullStatus.type.detail=='Final')?formatEarnings(competitor.earnings,1):competitor.score}</div>
              </div>
          </a>

      `;
  
      tableEl.append(competitorDiv);
  
    });
  

    jQuery(function() {
  
        var slideCount =  jQuery(".ScoreCellAthlete").length;
        var slideWidth =  jQuery(".ScoreCellAthlete").width();
        var slideUlWidth =  slideCount * slideWidth;

        console.log(slideUlWidth);
        
        jQuery(".HeaderScoreboard__Events").css({"width":slideUlWidth});
        jQuery(".HeaderScoreboard__SportSection").css({"width":slideUlWidth });
        // jQuery(".HeaderScoreboard__SportSection .ScoreCellAthlete:last-child").prependTo(jQuery(".HeaderScoreboard__SportSection"));
        
        function moveLeft() {
          jQuery(".HeaderScoreboard__SportSection").stop().animate({
            left: + slideWidth
          },700, function() {
            jQuery(".HeaderScoreboard__SportSection .ScoreCellAthlete:last-child").prependTo(jQuery(".HeaderScoreboard__SportSection"));
            jQuery(".HeaderScoreboard__SportSection").css("left","");
          });
        }
        
        function moveRight() {
          jQuery(".HeaderScoreboard__SportSection").stop().animate({
            left: - slideWidth
          },700, function() {
            jQuery(".HeaderScoreboard__SportSection .ScoreCellAthlete:first-child").appendTo(jQuery(".HeaderScoreboard__SportSection"));
            jQuery(".HeaderScoreboard__SportSection").css("left","");
          });
        }
        
        
        jQuery(".HeaderScoreboard__Carousel__Nav--next").on("click",function(){ 
          moveRight();
        });
        
        jQuery(".HeaderScoreboard__Carousel__Nav--prev").on("click",function(){
          moveLeft();
        });
        
        
      });
  
}



// Wait for the window to load
window.addEventListener( "load", () => {
    getScoreboard();
});


